import { createContext, useContext } from 'react';
import { CONSULTATION_DEFAULT_RESPONSE } from '@fiverr-private/consultation';
import { CurrencyConverter, SellerInfoData } from '../types';

export const SellerInfoContext = createContext<SellerInfoData>({
  completedOrders: 0,
  countryCode: '',
  languages: [],
  username: '',
  sellerId: 121,
  gigId: 1234,
  offerId: 'abc1234',
  isSmallScreen: false,
  consultationData: CONSULTATION_DEFAULT_RESPONSE,
  currencyConverter: {} as CurrencyConverter,
  isRestrictedPage: false,
});

export const SellerInfoProvider = SellerInfoContext.Provider;

export const useSellerInfoContext = () => useContext(SellerInfoContext);
