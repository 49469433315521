import React from 'react';
import classNames from 'classnames';
import ProfileImageBase from '@fiverr-private/profile_image';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import { useSellerInfoContext } from '../../context/SellerInfoContext';

import { profileImageClassName } from './ProfileImage.ve.css';

const PROFILE_IMAGE_SIZE = 120;

interface ProfileImageUser {
  username: string;
  profile_photo?: string;
  has_profile_photo: boolean;
  is_seller: true; // we show only sellers here
  is_pro: boolean;
}

export const ProfileImage = () => {
  const { introVideoUrl, username, profileImage, isAgency, isPro = false } = useSellerInfoContext();
  const hasProfilePhoto = !!profileImage;

  const user = {
    username,
    profile_photo: profileImage,
    has_profile_photo: hasProfilePhoto,
    is_seller: true,
    is_pro: isPro,
  } satisfies ProfileImageUser;

  const introVideo = hasProfilePhoto ? introVideoUrl : undefined;
  const profileImageClassNames = classNames({
    [profileImageClassName]: !introVideo,
  });

  if (isAgency) {
    return (
      <Avatar size="2xl" shape="square" username={username}>
        <AvatarImage src={profileImage} alt={username} />
      </Avatar>
    );
  }

  return (
    <ProfileImageBase
      user={user}
      profileImageSize={PROFILE_IMAGE_SIZE}
      mode="video_intent"
      introVideo={introVideo}
      profileImageClassName={profileImageClassNames}
    />
  );
};
