import React from 'react';
import { noop } from 'lodash';
import { Container } from '@fiverr-private/layout_components';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { useSellerInfoContext } from '../../context/SellerInfoContext';

export const ContactSeller = () => {
  const { ContactTrigger, isAgency, onContactSellerClick = noop, isRestrictedPage } = useSellerInfoContext();

  if (!ContactTrigger) {
    return null;
  }

  const contactSellerKey = isAgency
    ? 'gig_seller_info.summary.message_seller_cta_agency'
    : 'gig_seller_info.summary.message_seller_cta';

  return (
    <ContactTrigger onClick={onContactSellerClick}>
      {({ onClick }) => (
        <Container width="auto">
          <Button size="sm" variant="outline" intent="secondary" onClick={onClick} disabled={isRestrictedPage}>
            <I18n k={contactSellerKey} />
          </Button>
        </Container>
      )}
    </ContactTrigger>
  );
};
