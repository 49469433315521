import React from 'react';
import { first } from 'lodash';
import { Stack } from '@fiverr-private/layout_components';
import { SellerInfoData, SellerProfileInfo } from '@fiverr-private/gig_seller_info';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { OnTagsEvent, Tag, TagCollection } from '@fiverr-private/tag_collection';
import { SellerDescription } from '@fiverr-private/gig_seller_description';
import { useImpressionableRef } from '../../hooks/useImpressionableRef';
import { mpViewedSellerCard } from '../../events/mixpanel/viewedSellerCard';
import { bqClickedOnTagsShowMoreInGigPage } from '../../events/bigQuery/clickedOnTagsShowMoreInGigPage';
import { bqSellerTagsInGigPageIsShown } from '../../events/bigQuery/sellerTagsInGigPageIsShown';
import { mpClickedTagsShowMore } from '../../events/mixpanel/clickedTagsShowMore';
import { SellerCardContext, SellerCardContextProviderProps } from '../SellerCardContext/SellerCardContext';
import { mpDescriptionClicked } from '../../events/mixpanel/descriptionClicked';
import { bqClickedOnShowMoreAtSellerDescription } from '../../events/bigQuery/clickedOnShowMoreAtSellerDescription';
import { mpContactMeClicked } from '../../events/mixpanel/contactMeClicked';
import { mpSellerNameClicked } from '../../events/mixpanel/sellerNameClicked';
import { bqClickedOnContactSeller } from '../../events/bigQuery/clickedOnContactMe';
import { bqClickedOnSellerName } from '../../events/bigQuery/clickedOnSellerName';
import { Skills } from './Description/Skills/Skills';

const IMPRESSION_THRESHOLD = 0.25;

export const SellerCard: React.FC<SellerCardContextProviderProps> = ({ sellerInfo, metric }) => {
  const {
    currencyConverter,
    consultationData,
    biEvents,
    seller: { displayName },
    general: { sellerId, gigId },
    isRestrictedPage,
  } = useGigPageContext();
  const {
    sellerUrl,
    profilePhotoUrl,
    tags,
    username,
    location,
    introVideoUrl,
    spokenLanguages = [],
    completedOrders,
    isPro = false,
    isAgency,
    professionTitle,
    rating,
    Badges,
  } = sellerInfo;

  const ref = useImpressionableRef({
    onImpression: () => biEvents.sendMixPanelEvent(mpViewedSellerCard(sellerInfo)),
    threshold: IMPRESSION_THRESHOLD,
  });

  const onViewTags: OnTagsEvent = ({ tags, tagsToShow }) => {
    const tagsLabels = tags.map(({ label }) => label);
    const tag = first(tags) as Tag;

    biEvents.sendRawBigQueryEvent(
      bqSellerTagsInGigPageIsShown({
        tags: tagsLabels,
        tagsToShow,
        type: tag.type,
        metadataType: tag.type === 'metadata' ? tag.key : '',
        enrichmentData: biEvents.getBigQueryEnrichmentData(),
      })
    );
  };

  const onShowMoreTags: OnTagsEvent = ({ tags, tagsToShow }) => {
    const tagsLabels = tags.map(({ label }) => label);
    const tag = first(tags) as Tag;

    biEvents.sendRawBigQueryEvent(
      bqClickedOnTagsShowMoreInGigPage({
        tags: tagsLabels,
        tagsToShow,
        type: tag.type,
        metadataType: tag.type === 'metadata' ? tag.key : '',
        enrichmentData: biEvents.getBigQueryEnrichmentData(),
      })
    );

    biEvents.sendMixPanelEvent(mpClickedTagsShowMore());
  };

  const onReadToggle = (isExpanded: boolean) => {
    if (isExpanded) {
      return;
    }

    biEvents.sendMixPanelEvent(mpDescriptionClicked());
    biEvents.sendRawBigQueryEvent(
      bqClickedOnShowMoreAtSellerDescription({ enrichmentData: biEvents.getBigQueryEnrichmentData() })
    );
  };

  const sellerDescriptionData = {
    oneLinerTitle: sellerInfo.title,
    description: sellerInfo.description,
    skills: sellerInfo.skills?.map(({ label }) => label) || [],
  };

  const onContactSellerClick = () => {
    biEvents.sendMixPanelEvent(mpContactMeClicked());
    biEvents.sendRawBigQueryEvent(
      bqClickedOnContactSeller({
        enrichmentData: biEvents.getBigQueryEnrichmentData(),
      })
    );
    metric.count('contact_me.click');
  };

  const onSellerNameClick = () => {
    biEvents.sendMixPanelEvent(mpSellerNameClicked());
    biEvents.sendRawBigQueryEvent(
      bqClickedOnSellerName({
        enrichmentData: biEvents.getBigQueryEnrichmentData(),
      })
    );
  };

  const sellerInfoData: SellerInfoData = {
    isPro,
    professionTitle,
    rating,
    Badges,
    displayName,
    gigId,
    sellerId,
    sellerUrl,
    username,
    location,
    completedOrders,
    introVideoUrl,
    spokenLanguages,
    isAgency,
    currencyConverter,
    profileImage: profilePhotoUrl,
    consultationData,
    ContactTrigger: sellerInfo.ContactTrigger,
    onContactSellerClick,
    onSellerNameClick,
    isRestrictedPage,
  };

  return (
    <SellerCardContext.Provider value={{ sellerInfo, metric }}>
      <Stack direction="column" gap="8" ref={ref}>
        <SellerProfileInfo data={sellerInfoData} />
        <SellerDescription data={sellerDescriptionData} onReadToggle={onReadToggle} SkillsComponent={<Skills />} />
        {!isPro && (
          <TagCollection tags={tags} parentRef={ref} onViewTags={onViewTags} onShowMoreTags={onShowMoreTags} />
        )}
      </Stack>
    </SellerCardContext.Provider>
  );
};
